import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import GenericRoutes from "./Routes";
import * as Component from "./components/common";

function App() {
  //Smooth Scroll
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  //PopUp Box
  useEffect(() => {
    const popupTimeout = setTimeout(() => {
      setShowPopup(true);
    }, 300000);

    return () => {
      clearTimeout(popupTimeout);
    };
  }, [showPopup]);

  return (
    <div>
      <Component.Header />
      {showPopup && <Component.PopUp onClose={handleClosePopup} />}
      <GenericRoutes />
      <Component.Footer />
    </div>
  );
}

export default App;
