import BlogData from "../blog.json";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function BlogDetailPage() {
  // Get id from URL and check the id in json and fetch corresponding data into BlogData
  let { id } = useParams();
  let res = BlogData.filter((blog) => {
    return blog.id === id;
  });
  let BlogDetail = res[0];
  let BlogContent = BlogDetail.detailPage;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{BlogDetail.meta_title}</title>
        <meta name="description" content={BlogDetail.meta_description} />
      </Helmet>
      <main className="p-5 bg-white antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article className="mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg format-blue">
            <header className="mb-4 lg:mb-6 not-format">
              <address className="flex items-center mb-6 not-italic"></address>
              <h1 className="mb-4 text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4x">
                {BlogDetail.title}
              </h1>
            </header>
            <p className="text-justify">{BlogContent?.blog_detail}</p>
            <figure>
              <img
                className="w-1/2 mx-auto mt-4"
                src={BlogDetail.img}
                alt={BlogDetail.title}
              />
              <figcaption className="text-center italic pb-5">
                {BlogContent?.img_detail}
              </figcaption>
            </figure>
            {BlogContent.content.map((item, index) => {
              const formattedContent = item.content.replace(/\n/g, "<br />");

              return (
                <div key={index}>
                  <h2 className="font-bold text-xl">{item.title}</h2>
                  <p
                    className="py-5 text-justify"
                    dangerouslySetInnerHTML={{ __html: formattedContent }}
                  />
                </div>
              );
            })}
          </article>
        </div>
      </main>
    </div>
  );
}

export default BlogDetailPage;
